import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`On the web, `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#Art_direction"
        }}>{`art direction`}</a>{` refers to changing the image rendered at different display sizes. The `}<inlineCode parentName="p">{`ArtDirection`}</inlineCode>{` component allows you to provide multiple images achieve this goal.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <ArtDirection mdxType="ArtDirection">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAx0lEQVQoz7VSSwqDMBT0CmoEo/ETyDJHcONavIMQBJfZuvIUHsADeMaRF7AUWrG2djGQN8yb98nz8jzHnfDekVmWnSYeabwjoRACaZo+OEKSJNcNd7GUEkopcM5RlqXjtdaI49hxR6YvhpQcBAH6vse6rpimCfM8YxxHLMvi4qZpEEURiqI4NyQRdVHXNdq2hTEG1lp0XeeKDMOAqqqc5iPDfQxKoC7CMITv+2CMuc4pvjTyLqTqBFoB4fn91afcdjZ/OexfsAGrODYkF5oO6gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile image",
          "title": "Mobile image",
          "src": "/static/01d248b08a777674c499d124420894e5/3cbba/mobile.png",
          "srcSet": ["/static/01d248b08a777674c499d124420894e5/7fc1e/mobile.png 288w", "/static/01d248b08a777674c499d124420894e5/a5df1/mobile.png 576w", "/static/01d248b08a777674c499d124420894e5/3cbba/mobile.png 1152w", "/static/01d248b08a777674c499d124420894e5/b5909/mobile.png 1400w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAuUlEQVQoz7WSMQqEMBBFvUKwMdGoIWU6BS+QcwhimdLSCyjYB2/7l1lI2EJZZd3iMT/JZPjMTFKWJZ4kObqUUn79eJaTnCUWRQHOedREeA85R0VPHWqt0TQNhBBvTZCmwlVVXXdIkLO2bbFtG9Z1xbIs2Pcd8zzDe4+u65Bl2fWC5EIpBWstpmnCOI5wzmEYBvR9D2MM8jy/10OK5IIxhjRNYyQ++3lrytSruq4j4Xxryo+vzV8W+xde25I0sNgqHU8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tablet image",
          "title": "Tablet image",
          "src": "/static/c191985069deae382fed331245351ce5/3cbba/tablet.png",
          "srcSet": ["/static/c191985069deae382fed331245351ce5/7fc1e/tablet.png 288w", "/static/c191985069deae382fed331245351ce5/a5df1/tablet.png 576w", "/static/c191985069deae382fed331245351ce5/3cbba/tablet.png 1152w", "/static/c191985069deae382fed331245351ce5/b5909/tablet.png 1400w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA2UlEQVQoz7VSSwqDQAydK4hfxv9Sl4Nn8BQiuHIlegERF+Jaj+BFX8nASFustbRdPJJMXh5JJiwIAvwS7PnB9/3LxUdcdkTwPA+cc+mHYShjyhEoJlwSVKQ4jpEkCWzbhq7riKJIilDOsiwJ13UPRR8EqYhE8jzHtm3o+x7jOGJdVwzDgHme0bYtpmmCEGKf4rRDImVZhrquUZaltF3XoSgKVFWFpmmwLAvSNN27fLtDItKoCpqmwTAM6ZumCcdx9u5OR74nqL0RlK/s5V/+5Gxe8djfD/tb3ADXNzj3h/0mUgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Desktop image",
          "title": "Desktop image",
          "src": "/static/1dc892f5454c18d5636e18eba860dd32/3cbba/desktop.png",
          "srcSet": ["/static/1dc892f5454c18d5636e18eba860dd32/7fc1e/desktop.png 288w", "/static/1dc892f5454c18d5636e18eba860dd32/a5df1/desktop.png 576w", "/static/1dc892f5454c18d5636e18eba860dd32/3cbba/desktop.png 1152w", "/static/1dc892f5454c18d5636e18eba860dd32/b5909/desktop.png 1400w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </ArtDirection>
    <h2>{`Code`}</h2>
    <p>{`You can place up to three images inside of the ArtDirection component. The first will be used for mobile, the second for tablet, and the third for desktop. If only two images are provided, the second image will be used for both tablet and desktop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "path=components/ArtDirection.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ArtDirection",
        "path": "components/ArtDirection.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ArtDirection"
      }}>{`<ArtDirection>

![Mobile image](./mobile.png)
![Tablet image](./tablet.png)
![Desktop image](./desktop.png)

</ArtDirection>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      